import { 
   autentica,
   pushUser
} from '../../services/api'

//const dispatch = useDispatch()

export const loadAutentica = async (dispatch) => {
   try {
      dispatch({ type: 'LOAD_AUTENTICA_REQUEST' })

      const { data } = await autentica()

      if(data) {
         if(!data.status) {
            console.log('Erro inesperado ao carregar dados do usuário.', data)
            dispatch({ type: 'LOAD_AUTENTICA_ERROR', message: 'Erro inesperado ao carregar dados do usuário.'  })
         } else {
            dispatch({ type: 'LOAD_AUTENTICA_SUCCESS', data  })
         }
      } else {
         console.log('Erro inesperado ao carregar dados do usuário.', data)
         dispatch({ type: 'LOAD_AUTENTICA_ERROR', message: 'Erro inesperado ao carregar dados do usuário.'  })
      }
   } catch(err) {
      console.log('Erro inesperado ao carregar dados do usuário.', err)
      dispatch({ type: 'LOAD_AUTENTICA_ERROR', message: 'Erro inesperado ao carregar dados do usuário.'  })
   }
}

export const createUser = async (dispatch, nome) => {
   try {
      dispatch({ type: 'CREATE_USER_REQUEST' })

      const { data } = await pushUser({ nome, telefone: localStorage.telefone })

      if(data.status) {

         localStorage.nome = nome

         dispatch({ type: 'CREATE_USER_SUCCESS', nome: nome })

         //dispatch({ type: 'CREATE_USER_SUCCESS' })

      } else  {
         dispatch({ type: 'CREATE_USER_REQUEST', message: data.message || 'Erro ao criar nome de usuario' })
      }
   } catch(err) {
      console.log('Erro ao salvar nome: ', err.message )
      dispatch({ type: 'CREATE_ADRESS_REQUEST', message: err.message })
   }
}