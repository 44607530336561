import React from 'react'
import { Route } from 'react-router-dom'

const RouteRun = ({ component: Component, ...rest }) => (
   <Route {...rest} render={ props => {
      
      return <Component {...props} />
   } } />
)

export default RouteRun