const INNITIAL_STATE = {
   isFetching  : false,
   pedido      : { status: '' },
   empresa     : {},
   error       : false,
   messageError: '',
   hash        : ''
}

const system = (state = INNITIAL_STATE, action) => {
   switch (action.type) {
      case 'VERIFY_HASH_REQUEST':
         return {
            ...state,
            isFetching  : true,
            hash        : action.hash
         }
      case 'VERIFY_HASH_SUCCESS':
         return {
            ...state,
            isFetching  : false,
            pedido      : action.data.pedido,
            empresa     : action.data.empresa
         }
      case 'VERIFY_HASH_ERROR':
         return {
            ...state,
            error       : true,
            messageError: action.message
         }
      case 'CANCEL_RUN':
         return {
            ...state,
            pedido: {
               status: 'cancelado'
            }
         }
      case 'CHANGE_STATUS': 
      return {
         ...state,
         pedido: {
            status: action.status
         }
      }
      case 'LABEL_EMPRESA':
         return {
            ...state,
            empresa: action.data
         }
      default:
         return state
   }
}

export default system