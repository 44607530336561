import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Button,  Divider, Grid, TextField, Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import CustomGoogleMaps from '../maps/CustomGoogleMaps'
import AutocompletePlaces from '../maps/AutocompletePlaces'
import Confirm from '../Corfirm'

import Payment from '../user/Payment'

import { getPlaceByGeocode, extractComponentPlace } from '../../helpers/getLocationMaps'

import { solicitRun, newAndSolicit } from '../../store/actions/corrida'

const useStyles = makeStyles(theme => ({
   btn: {
      width: '95%',
      backgroundColor: '#eee',
      marginTop: 10,
      marginBottom: 10
   },
   list: {
      
   },
   subHeader: {
      margin: 5
   }
}))

function UserAdresses(props) {
   const dialogSearchBox = useSelector(state => state.configComponents.dialogSearchBox)
   const position = useSelector(state => state.position)
   const user     =  useSelector(state => state.user)
   const dispatch = useDispatch()
   const classes = useStyles()
   const [endereco, setEndreco] = useState('')
   const [google_adress, setGoogle_adress] = useState({})
   const [coordByGoogleAdress, setCoordByGoogleAdress] = useState({ latitude: '', longitude: '' })
   const [sortPlace, setSortPlace] = useState('')
   const [solictiByRegistred, setSolictiByRegistred] = useState({})
   const [openModal, setOpenModal] = useState(false)
   

   const getLocateInput = (result, detailRegistred) => {
      if(!result) {
         setSolictiByRegistred({
            telefone    : localStorage.telefone,
            endereco_id : detailRegistred.id,
            hash        : localStorage.hash
         })
         setSortPlace(`${detailRegistred.logradouro}, ${detailRegistred.bairro}, ${detailRegistred.cidade}`)
      } else {
         setEndreco(result.formatted_address)
         setGoogle_adress(result.address_components)
         setCoordByGoogleAdress({ latitude: result.geometry.location.lat, longitude: result.geometry.location.lng })
         dispatch({ type: 'LOAD_POSITION_SUCCESS', position: {lat: result.geometry.location.lat, lng: result.geometry.location.lng} })
         const ext = extractComponentPlace(result.address_components)
         setSortPlace(
            ext.logradouro ?
               ext.logradouro + ', ' + ext.bairro + ', ' + ext.cidade
            :
               (`${Object.keys(ext).map(key=>ext[key]).filter(_=>_)}`.replace(/,/gi, ', '))
         )
         setSolictiByRegistred({})
      }
   }

   const solicitByGoogle = async (pagamento) => {
      let end = {}
      end.logradouro = google_adress.find( comp => comp.types.map( t => t === 'route' ).find(_=>_)  ) || {}
      end.numero     = google_adress.find( comp => comp.types.map( t => t === 'street_number').find(_=>_) ) || {}
      end.bairro     = google_adress.find( comp => comp.types.map( t => t === 'sublocality' ).find(_=>_) ) || {}
      end.cidade     = google_adress.find( comp => comp.types.map( t => t === 'administrative_area_level_2' ).find(_=>_) ) || {}
      end.estado     = google_adress.find( comp => comp.types.map( t => t === 'administrative_area_level_1' ).find(_=>_) ) || {}
      end.cep        = google_adress.find( comp => comp.types.map( t => t === 'postal_code' ).find(_=>_) ) || {}
      Object.keys(end).forEach( key => {
         if(key === 'estado' && 'short_name' in end[key]) return end[key] = end[key].short_name
         if('long_name' in end[key]) return end[key] = end[key].long_name
         return end[key] = ''
      })

      //console.log({ ...end, latitude: coordByGoogleAdress.latitude, longitude: coordByGoogleAdress.longitude })
      await newAndSolicit(dispatch, {
         endereco: { ...end, latitude: coordByGoogleAdress.latitude, longitude: coordByGoogleAdress.longitude },
         nome: user.nome,
         pagamento
      })

      return props.history.push('/corrida')
   }

   const solicit = (pagamento) => {
      if(Object.keys(solictiByRegistred).length) {
         solicitRun(dispatch, {...solictiByRegistred, pagamento})
         return props.history.push('/corrida')
      } else {
         solicitByGoogle(pagamento)
      }
   }

   function closeModal() {
      setTimeout(() => setOpenModal(false), 400)      
   }

   useEffect(() => {
      if(position.status === 'localizado') {
         const getPlace = async () => {
            const result = await getPlaceByGeocode(position.coords)
            if(result.status) {
               setEndreco(result.result[0].formatted_address)
               setGoogle_adress(result.result[0].address_components)
               const ext = extractComponentPlace(result.result[0].address_components)
               setSortPlace(
                  ext.logradouro ?
                  ext.logradouro + ', ' + ext.bairro + ', ' + ext.cidade
                  :
                     (`${Object.keys(ext).map(key=>ext[key]).filter(_=>_)}`.replace(/,/gi, ', '))
               )
            } else {
               setEndreco('Não foi possível encontrar sua localização')
            }
         }

         getPlace()
      }
   }, [position.status])

   return (
      <div style={{ width: 'auto', height: '100vh' }}>
         
         {/* <Confirm openModal={openModal} closeModal={ closeModal } onConfirmed={ solicit } label="Clique em OK para confirmar o pedido" /> */}

         {  openModal &&
            <Payment cancel={ () => closeModal() } confirm={ data => solicit(data) } />
         }

         {  dialogSearchBox &&
            <AutocompletePlaces placesRegistreds={user.enderecos} currentPlace={endereco} getLocateInput={getLocateInput} />
         }

         <CustomGoogleMaps center={position.coords} zoom={14} />

         <Typography align='center' className={classes.subHeader}>Olá, {user.nome}</Typography>

         <Divider />

         {  endereco &&
            <Grid container direction="row" justify='center'>
               <Typography variant='subtitle1' align='center'>
                  Onde estou:               
               </Typography>
               <TextField
                  style={{ width: '95%' }}
                  autoFocus={true} multiline={true} value={sortPlace}
                  onClick={() => dispatch({ type: 'OPEN_DIAOLOG_SEARCH' })}
               />
            </Grid>
         }
         
         <Grid container direction="row" justify='center'>
            <Button 
               className={classes.btn} 
               onClick={() => setOpenModal(true)}
            >
               Pedir táxi
            </Button>
         </Grid>

      </div>
   )
}

export default withRouter(UserAdresses)