import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { 
   Button, Dialog, DialogActions, 
   DialogContent,  DialogTitle,
   Radio, RadioGroup, FormControlLabel,
   Typography
} from '@material-ui/core'

import { 
   CreditCard, AttachMoney, LocalAtm
} from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
   content: {
     margin: 'auto'
   },
   button: {
      margin: theme.spacing(1),
      width: 100
   }
}))

function Payment(props) {
   const classes                       = useStyles()
   const [forma, setForma]             = useState('')
   const [bandeira, setBandeira]       = useState('')
   const [chooseFlag, setChooseFlag]   = useState(false)
   const [confirm, setConfirm]         = useState(false)
   const empresa                       = useSelector( state => state.system.empresa )
   
   useEffect(() => {
      setChooseFlag( ( forma === 'cartao_credito' || forma === 'cartao_debito' ) )
   }, [forma])

   const prettyConfirmMsg = ({forma, bandeira}) => {
      const labelForma = {
         'dinheiro'        : 'Dinheiro',
         'cartao_credito'  : 'Cartão de Crédito',
         'cartao_debito'   : 'Cartão de Débito',
         'pix'             : 'PIX'
      }

      const labelBandeira = {
         'master': 'Mastercard',
         'visa': 'Visa'
      }
      
      return forma === 'dinheiro' || forma === 'pix'
      ? 
         `Pagar em ${labelForma[forma]}`
      :
         `Pagar com ${labelForma[forma]} ${labelBandeira[bandeira]}` 
   }
   
   return (
      <>
         {  localStorage.e_ticket
            ?
               <Dialog open={true}>
                  <DialogTitle>Confirmar Corrida pelo E-Ticket</DialogTitle>
                  <DialogActions>
                     <Button 
                        color="primary"                  
                        onClick={ () => props.cancel() }
                     >
                        Voltar
                     </Button>
                     <Button 
                        color="primary" 
                        onClick={() => props.confirm({ e_ticket: localStorage.e_ticket, desconto: empresa.desconto })}
                     >
                        Confirmar
                     </Button>
                  </DialogActions>
               </Dialog>
            :
               <>
                  {  confirm
                     ?
                        <Dialog open={true}>
                           <DialogTitle>Confirmar forma de pagamento</DialogTitle>
                           <DialogContent>
                              <Typography>
                                 { prettyConfirmMsg({ forma, bandeira }) }
                              </Typography>                           
                              {/* {  forma === 'outros' &&
                                 <>
                                    <br />
                                    <Typography variant="caption">
                                       Você será redirecionado para o WEB APP da Original.
                                    </Typography>
                                 </>
                              } */}
                           </DialogContent>
                           <DialogActions>
                              <Button 
                                 color="primary"                  
                                 onClick={() => {setForma(''); setBandeira(''); setConfirm(false)} }
                              >
                                 Voltar
                              </Button>
                              <Button color="primary" onClick={() => props.confirm({ forma, bandeira, desconto: empresa.desconto }) } >
                                 Confirmar
                              </Button>
                           </DialogActions>
                        </Dialog>
                     :
                        <Dialog open={true}>
                           {  chooseFlag
                              ?
                                 <DialogTitle>Bandeira do Cartão</DialogTitle>
                              :
                                 <DialogTitle>Opçoes de Pagamento</DialogTitle>
                           }

                           <DialogContent>
                              { chooseFlag
                                 ?
                                    <div>
                                       <RadioGroup value={bandeira} onChange={e => setBandeira(e.target.value)}>
                                          <FormControlLabel value="master" control={<Radio color='primary' />} label="Mastercard" />
                                          <FormControlLabel value="visa" control={<Radio color='primary' />} label="Visa" />
                                       </RadioGroup>
                                    </div>
                                 :
                                    <div className={classes.content} >
                                       <Button 
                                          variant="outlined" 
                                          size="small" 
                                          className={classes.button} 
                                          endIcon={<LocalAtm />} 
                                          onClick={ () => {setConfirm(true); setForma('dinheiro')} }
                                       >
                                          Dinheiro
                                       </Button>

                                       <Button 
                                          variant="outlined" 
                                          size="small" 
                                          className={classes.button} 
                                          onClick={ () => setForma('cartao_credito') }
                                          endIcon={<CreditCard />}
                                       >
                                          Crédito                  
                                       </Button>

                                       <Button 
                                          variant="outlined"
                                          size="small" 
                                          className={classes.button} 
                                          onClick={ () => setForma('cartao_debito') }
                                          endIcon={<CreditCard />}
                                       >
                                          Débito                  
                                       </Button>

                                       <Button 
                                          variant="outlined"
                                          size="small" 
                                          className={classes.button} 
                                          onClick={ () => {setConfirm(true); setForma('pix')} }
                                       >
                                          PIX                
                                       </Button>

                                       {/* {  empresa.webapporiginal_url &&
                                          <Button 
                                             variant="outlined" 
                                             size="small" 
                                             className={classes.button}
                                             href={empresa.webapporiginal_url} 
                                             target="_blank"
                                             onClick={ () => {setForma('outros'); setConfirm(true)} }
                                             endIcon={<AttachMoney />}
                                          >
                                             outros                  
                                          </Button>
                                       } */}
                                    </div>
                              }
                           </DialogContent>
                           
                           {  chooseFlag
                              ?
                                 <DialogActions>
                                    <Button 
                                       color="primary"                     
                                       onClick={() => {setForma(''); setBandeira('')} }
                                    >
                                       Voltar
                                    </Button>
                                    <Button disabled={!bandeira} color="primary" onClick={() => setConfirm(true) } >
                                       Pedir
                                    </Button>
                                 </DialogActions>
                              :

                                 <DialogActions>
                                    <Button color="primary" onClick={() => props.cancel() }>
                                       Cancelar
                                    </Button>
                                 </DialogActions>
                           }
                        </Dialog>
                  }
               </>
            
         }
         
      </>
   )
}

export default Payment