import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import store from './store'

import SwitchRoutes from './routes'

import InitWebSocket from './InitWebSocket'

import Header from './components/Header'
//import NewAdress from './components/NewAdress'
//import CustomSnackbar from './components/CustomSnackbar'
import LoadingRequest from './components/LoadingRequest'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  content: {
    paddingTop: 90,
    [theme.breakpoints.down(425)]: {
      paddingTop: 60
    }
  }
}))

function App() {
  const classes = useStyles()

  return (
    <Provider store={store}>
      <BrowserRouter>
        <InitWebSocket>
          <div className={classes.root}>
            <Grid container direction="row" justify='center'>

              <Grid item xs={12}>
                <Header />
              </Grid>

              <Grid item xs={12} sm={10} md={8} lg={6} className={classes.content}>
                {/* <NameUser /> */}
                {/* <NewAdress /> */}
                {/* <CustomSnackbar /> */}
                <LoadingRequest />
                <SwitchRoutes />
              </Grid>
            </Grid>
          </div>
          </InitWebSocket>
      </BrowserRouter>
    </Provider>
  )
}

export default App
