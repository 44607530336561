import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'

const variantIcon = {
   success: CheckCircleIcon,
   warning: WarningIcon,
   error: ErrorIcon,
   info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
   success: {
      backgroundColor: green[600],
   },
   error: {
      backgroundColor: theme.palette.error.dark,
   },
   info: {
      backgroundColor: theme.palette.primary.main,
   },
   warning: {
      backgroundColor: amber[700],
   },
   icon: {
      fontSize: 20,
   },
   iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
   },
   message: {
      display: 'flex',
      alignItems: 'center',
   },
}));

function MySnackbarContentWrapper(props) {
   const classes = useStyles1();
   const { className, message, onClose, variant, ...other } = props;
   const Icon = variantIcon[variant];

   return (
      <SnackbarContent
         className={clsx(classes[variant], className)}
         aria-describedby="client-snackbar"
         message={
            <span id="client-snackbar" className={classes.message}>
               <Icon className={clsx(classes.icon, classes.iconVariant)} />
               {message}
            </span>
         }
         action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
               <CloseIcon className={classes.icon} />
            </IconButton>,
         ]}
         {...other}
      />
   );
}

MySnackbarContentWrapper.propTypes = {
   className: PropTypes.string,
   message: PropTypes.string,
   onClose: PropTypes.func,
   variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

export default function CustomizedSnackbars() {
   const alert = useSelector( state => state.configComponents.alert )
   const dispatch = useDispatch()

   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }

      dispatch({type: 'SET_ALERT', action: {open: false, message: '', type: 'error'}})
   };

   return (
         <Snackbar
            anchorOrigin={{
               vertical: 'top',
               horizontal: 'center',
            }}
            open={alert.open}
            autoHideDuration={8000}
            onClose={handleClose}
         >
            <MySnackbarContentWrapper
               onClose={handleClose}
               variant="error"
               message={alert.message}
            />
         </Snackbar>
   );
}