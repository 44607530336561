import { newAdressAndSolicit, consultaRun, cancelRun, solicita } from '../../services/api'

import { loadAutentica } from './user'

export const newAndSolicit = async (dispatch, obj) => {
   try {
      dispatch({ type: 'CREATE_ADRESS_REQUEST' })
      obj.hash     = localStorage.hash
      obj.telefone = localStorage.telefone

      const { data } = await newAdressAndSolicit(obj)

      if(data.status) {
         dispatch({ type: 'CREATE_ADRESS_SUCCESS', data })

         loadAutentica(dispatch)
      } else {
         console.log('criado status false: ', data)
         dispatch({ type: 'CREATE_ADRESS_ERROR', message: data.message || 'Erro ao solicitar táxi' })
      }      
   } catch (err) {
      console.log('Erro ao criar endereço: ', err)
      dispatch({ type: 'CREATE_ADRESS_ERROR', message: err.message })
   }
}

export const solicitRun = async (dispatch, obj) => {
   try {
      dispatch({ type: 'SOLICIT_TAXI_REQUEST' })

      const { data } = await solicita(obj)

      if(data.status) {
         dispatch({ type: 'SOLICIT_TAXI_SUCCESS' })

         loadAutentica(dispatch)
      } else {
         console.log('Erro ao solicitar corrida: ', data)
         dispatch({ type: 'SOLICIT_TAXI_ERROR', message: 'Erro ao solicitar corrida' })
      }
      
   } catch(err) {
      console.log('Erro ao solicitar corrida: ', err)
      dispatch({ type: 'SOLICIT_TAXI_ERROR', message: 'Erro ao solicitar corrida' })
   }
}

export const actionCancelRun = async (dispatch, hash) => {
   try {
      const { data } = await cancelRun(hash)

      if(data.status) dispatch({ type: 'CANCEL_RUN' })

      return data.status
   } catch(err) {
      console.log('Erro ao cancelar corrida: ', err.message )
   }
}

export const loadRun = async (dispatch) => {
   try {
      dispatch({ type: 'LOAD_RUN_REQUEST' })

      const { data } = await consultaRun()
      if(data.status) {
         dispatch({ type: 'LOAD_RUN_SUCCESS', data: data })
      } else {
         console.log('Erro ao buscar endereços: ', data)
         dispatch({ type: 'LOAD_RUN_ERROR', message: 'Erro ao carregar corridas.' })
      }

   } catch(err) {
      console.log('Erro ao buscar endereços: ', err)
      dispatch({ type: 'LOAD_RUN_ERROR', message: 'Erro ao carregar corridas.' })
   }
}