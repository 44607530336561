import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { 
   Button, CircularProgress, Dialog, 
   DialogActions, DialogTitle, DialogContent, 
   Grid, TextField, Typography 
} from '@material-ui/core'

import { createUser } from '../../store/actions/user'

import Payment from '../user/Payment'

function NameUser(props) {
   const [nome, setNome]               = useState('')
   const dispatch                      = useDispatch()
   const user                          = useSelector( state => state.user )
   const corrida                       = useSelector( state => state.corrida )

   /* useEffect(() => {
      if(user.nome && user.status === 'NAME_CREATED') {
         props.solicitTaxi()
      }
   }, [user.nome]) */

   const keyPress = e => {
      if(e.keyCode === 13) {         
         createUser(dispatch, nome)
      }
   }

   return (
      <div>
         {  (user.nome && user.status === 'NAME_CREATED') ?
               <Payment cancel={ () => props.close() } confirm={ data => props.solicitTaxi(data) } />
            :
               <Dialog open={props.open} aria-labelledby="form-dialog-title">
                  <DialogTitle>Informe seu nome</DialogTitle>
                  <DialogContent>
                     {  user.isCreating &&
                        <Grid container direction="row" justify='center'>
                           <CircularProgress style={{ margin: '2px' }} />
                           <Typography style={{width:'100%'}} variant='subtitle1' align='center'>Salvando o nome...</Typography>
                           
                        </Grid>
                     }

                     {  corrida.isCreating &&
                        <Grid container direction="row" justify='center'>
                           <CircularProgress style={{ margin: '2px' }} />
                           <Typography style={{width:'100%'}} variant='subtitle1' align='center'>Criando corrida...</Typography>
                           
                        </Grid>
                     }
                        
                     {  !user.isCreating && !corrida.isCreating &&
                        <TextField
                           autoFocus
                           margin="dense"
                           id="nome"
                           label="Digite seu nome"
                           type="text"
                           fullWidth
                           onChange={e => setNome(e.target.value)}
                           value={nome}
                           onKeyDown={keyPress}
                        />
                     }
                     
                  </DialogContent>
                  {  !user.isCreating && !corrida.isCreating &&
                     <DialogActions>
                        <Button color="primary" onClick={()=>props.close()}>
                           Cancelar
                        </Button>
                        <Button disabled={!nome} color="primary" onClick={() => createUser(dispatch, nome)}>
                           Enviar
                        </Button>
                     </DialogActions>
                  }
                  
            </Dialog>
         }
      </div>
   )
}

export default NameUser