import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { registerToSocket } from './services/websocket'

//import { loadRun } from './store/actions/corrida'

function InitWebSocket(props) {
   const dispatch = useDispatch()
   const system   = useSelector( state => state.system )

   useEffect(() => {
      if(system.hash) {
         const socket = registerToSocket()
         
         socket.on('status', data => {
            const { status, hash, corrida } = data
            /* if (status === 'solicitado' || status === 'despachada') {
               if (window.location.pathname !== '/corrida') {
                  props.history.push('/corrida')
               }
            } else {
               if(window.location.pathname !== '/') {
                  props.history.push('/')
               }               
               
            } */
            if(hash === localStorage.hash) {
               console.log('alterando status do user', status, hash, corrida)
               dispatch({  type: 'CHANGE_STATUS', status   })
               dispatch({  type: 'LOAD_DATA_RUN', data: corrida  })
            }

         })
      }
   }, [system.hash])

   return (
      <>
         {props.children}
      </>
   )
}

export default withRouter(InitWebSocket)