import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Grid, Toolbar, Typography, useScrollTrigger } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

//https://css3gen.com/wp-content/cache/all/box-shadow/index.html
const useStyles = makeStyles(theme => ({
   title: {
      textAlign: 'center',
      fontSize: 22,
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
         fontSize: 15
      },
      [theme.breakpoints.down('sm')]: {
         fontSize: 18
      }
   },

   logo: {
      boxShadow: '-1px 5px 8px -2px rgba(135, 135, 135, 0.49)',
      width: 'auto',
      height: 50,
      [theme.breakpoints.down('xs')]: {
         boxShadow: '-1px 5px 8px -2px rgba(135, 135, 135, 0.2)',
         height: 30
      }
   }
}))

function ElevationScroll(props) {
   const { children, window } = props;
   // Note that you normally won't need to set the window ref as useScrollTrigger
   // will default to window.
   // This is only being set here because the demo is in an iframe.
   const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
   });

   return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
   })
}

function Header(props) {
   const system = useSelector( state => state.system )
   const classes = useStyles()

   const logo     = system.empresa ? system.empresa.logo : ''
   const empresa  = system.empresa ? system.empresa.nome : ''
   const cor      = system.empresa ? system.empresa.cor  : '#f6f9fe'
   
   return (
      <>
         {  
            props.location.pathname === '/user' || 
            props.location.pathname === '/corrida' || 
            props.location.pathname === '/hotel' ||
            props.location.pathname === '/' ||
            props.location.pathname === '/corrida_convenio' ?
            <ElevationScroll {...props}>
               <AppBar style={{ backgroundColor: cor }}>
                  
                        <Toolbar>
                           <Grid container justify="flex-start" item xs={4}>
                              <img className={classes.logo} src={logo} alt='Logo' />
                           </Grid>
                           <Grid item xs={4}>
                              <Typography className={classes.title}>{empresa}</Typography>
                           </Grid>
                        </Toolbar>
                     
               </AppBar>
               
            </ElevationScroll>
            : null
         }
      </>
   )
}

export default withRouter(Header)