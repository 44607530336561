import React         from 'react'
import {
   Switch
}                    from 'react-router-dom'

import Corrida       from '../pages/Corrida'
import List          from '../pages/List'
import Loading       from '../pages/Loading'
import Home          from '../pages/Home'
import Hotel         from '../pages/Hotel'
import Convenio      from '../pages/Convenio'

import RouteIndex    from './RouteIndex'
import RouteList     from './RouteList'
import RouteHash     from './RouteHash'
import RouteRun      from './RouteRun'
import RouteHotel    from './RouteHotel'
import RouteConvenio from './RouteConvenio'

const Routes = () => (
   <Switch>
      <RouteIndex    path='/'                   exact component={ Home }         />
      <RouteList     path='/user'               exact component={ List }         />
      <RouteRun      path='/corrida'            exact component={ Corrida }      />
      <RouteHotel    path='/hotel'              exact component={ Hotel }        />
      <RouteConvenio path='/corrida_convenio'   exact component={ Convenio }     />
      <RouteHash     path='/:hash'              component={ Loading }            />
   </Switch>
)

export default Routes