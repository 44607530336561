import { 
   verifyHash,
   label,
   check_eticket
} from '../../services/api'

import { loadAutentica } from './user'

export const loading = async (dispatch, hash) => {
   try {
      dispatch({ type: 'VERIFY_HASH_REQUEST', hash })

      if(localStorage.hash !== hash) {
         Object.keys(localStorage).forEach( key => delete localStorage[key] )
         localStorage.hash = hash
      }

      let { data } = await verifyHash(hash)

      if ('status' in data && !data.status) {
         if(data.message === 'Pedido cancelado') {
            dispatch({ type: 'CHANGE_STATUS', status: 'cancelado' })
         } else if (data.message === 'Pedido cancelado') {
            dispatch({ type: 'CHANGE_STATUS', status: 'finalizado' })
         } else {
            dispatch({ type: 'CHANGE_STATUS', status: 'expirado' })
         }
         
      } else {
         if (data.empresa) {
            if(data.empresa.token)     localStorage.token = data.empresa.token
            if(data.empresa.cor)       localStorage.cor = data.empresa.cor
         }
         if (data.pedido) {
            if (data.pedido.telefone) {
               localStorage.telefone = data.pedido.telefone
               localStorage.e_ticket = data.pedido.e_ticket
               loadAutentica(dispatch)
            }
         }
         dispatch({ type: 'VERIFY_HASH_SUCCESS', data: data })         
      }

   } catch(err) {
      console.log('Erro ao verificar Hash: ', err)
      dispatch({ type: 'VERIFY_HASH_ERROR', message: err.message })
   }
}

export const loadingDataEMpresa = async dispatch => {
   try {
      const { data } = await label()

      if(data) {
         dispatch({ type: 'LABEL_EMPRESA', data })
         //localStorage.token = data.token
      }
   } catch(err) {
      console.log('Não possível pegar o Label da empresa: ', err)
   }
}

export const checkInfoEticket = async (dispatch, { telefone, pass_eticket }) => {
   try {
      const { data } = await check_eticket({ telefone, pass_eticket })

      return data
   } catch (err) {
      console.log('Não possível checar o Eticket: ', err)
      return { status: false, message: err.response && err.response.data ? err.response.data.message : 'Erro ao enviar pedido.' }
   }
}