const INITIAL_STATE = {
   circularProgress: {
      color: 'primary',
      status_spinner: false,
      message: '',
      open: false
   },
   dialogSearchBox: false,
   alert: {
      message: '',
      open: false,
      type: 'error'
   }
}

const configComponents = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case 'REQUEST_STARTED': 
         return {
            ...state,
            circularProgress: {
               color: 'primary',
               status_spinner: true,
               message: action.message,
               open: true
            }
         }
      case 'REQUEST_FINISHED': 
         return {
            ...state,
            circularProgress: {
               color: 'primary',
               status_spinner: false,
               message: action.message,
               open: true
            }
         }
      case 'REQUEST_CLOSE':
         return {
            ...state,
            circularProgress: {
               color: 'primary',
               status_spinner: false,
               message: '',
               open: false
            }
         }
      case 'OPEN_DIAOLOG_SEARCH':
         return {
            ...state,
            dialogSearchBox: true
         }
      case 'CLOSE_DIAOLOG_SEARCH':
         return {
            ...state,
            dialogSearchBox: false
         }
      case 'SET_ALERT':
         return {
            ...state,
            alert: {
               message  : action.action.message,
               open     : action.action.open,
               type     : action.action.type
            }
         }
      default:
         return state
   }
}

export default configComponents