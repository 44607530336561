const INNITIAL_STATE = {
   coords: {
      lat      : -14.2350044,
      lng      : -51.9252815
   },
   isError     : false,
   messageError: '',
   status      : 'initial'
}

const position = (state = INNITIAL_STATE, action) => {
   switch (action.type) {
      case 'LOAD_POSITION_SUCCESS':
         return { 
            ...state,
            coords: action.position,
            status:  'localizado'
         }
      case 'LOAD_POSITION_ERROR':
         return { 
            ...state,
            isError     : true,
            messageError: action.message,
            status      : 'error'
         }
      default:
         return state
   }
}

export default position