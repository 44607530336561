import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

const RouteHash = ({ component: Component, ...rest }) => {
   const system = useSelector( state => state.system )
   return (
      <Route {...rest} render={ props => {
         if( system.pedido.status === 'criado' ) {
            return <Redirect to={{ pathname: '/user', state: {from: props.location} }} />
         }
         if (system.pedido.status === 'expirado' || system.pedido.status === 'cancelado' || system.pedido.status === 'cancelada' || system.pedido.status === 'finalizado' || system.pedido.status === false ) {
            return <Redirect to={{ pathname: '/', state: {from: props.location} }} />
         }
         if (system.pedido.status === 'solicitado' || system.pedido.status === 'sucesso' || system.pedido.status === 'despachada' ) {
            return <Redirect to={{ pathname: '/corrida', state: {from: props.location} }} />
         }
         return <Component {...props} />
      } } />
   )
}

export default RouteHash