import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { WifiOff } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { loading } from '../store/actions/system'
import waiting from '../assets/images/waiting.gif'

function Loading(props) {
   const system   = useSelector( state => state.system )
   const dispatch = useDispatch()

   useEffect(() => {
      setTimeout(() => {
         loading(dispatch, props.match.params.hash)
      }, 2000)
      
   }, [])

   /* useEffect(() => {
      if (system.pedido.status) {
         if (system.pedido.status === 'expirado' || system.pedido.status === 'cancelado' || system.pedido.status === false) {
            return props.history.push('/invalid_hash')
         }
         if (system.pedido.status === 'created') {
            return props.history.push('/')
         }
      }
   }, [system.pedido.status]) */

   
   return (
      <Grid container direction='column' justify='center' alignContent='center' alignItems='center'>
         
         
         {  system.error ?
               <>
                  <Grid style={{marginTop: '20%'}}><WifiOff style={{ fontSize: 75 }} /></Grid>
                  <Grid><Typography align='center' >Falha na conexão, tente novamente em alguns minutos.</Typography></Grid>
               </>
            :
               <>
                  <Grid style={{marginTop: '20%'}}><img src={waiting} alt='Gif de loading' /></Grid>
                  <Grid><Typography align='center' >Buscando Informações...</Typography></Grid>
               </>
         }        
      </Grid>
   )
}

export default withRouter(Loading)