import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    Button,
    Card, CardActions, CardContent, 
    CardHeader, Grid, CircularProgress,
    TextField 
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'

import { loading, loadingDataEMpresa, checkInfoEticket } from '../store/actions/system'
import SnackBar from '../components/SnackBar'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        }
    }
}))

function TelefoneMaskInput(props) {
    const { inputRef, onChange, ...other } = props

    function format(number) {
        let nl = number.toString()
    
        if(nl.length <= 10) return `(${nl[0]||'#'}${nl[1]||'#'})${nl[2]||'#'}${nl[3]||'#'}${nl[4]||'#'}${nl[5]||'#'}-${nl[6]||'#'}${nl[7]||'#'}${nl[8]||'#'}${nl[9]||'#'}`
        return `(${nl[0]||'#'}${nl[1]||'#'})${nl[2]||'#'}${nl[3]||'#'}${nl[4]||'#'}${nl[5]||'#'}${nl[6]||'#'}-${nl[7]||'#'}${nl[8]||'#'}${nl[9]||'#'}${nl[10]||'#'}`

    }
 
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                    value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            format={format}
            placeholder='( ## ) ##### - ####'
            mask={['#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#']}
        />
    )
}

TelefoneMaskInput.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
}

function Convenio(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const system   = useSelector( state => state.system )
    const [pass_eticket, setPass_eticket] = useState('')
    const [telefone, setTelefone] = useState('')
    const [loadingg, setLoadingg] = useState(false)

    useEffect(() => {
        console.log('deletando')
        delete localStorage.hash
        delete localStorage.e_ticket
        loadingDataEMpresa(dispatch)
    }, [])

    useEffect(() => {
        if(system.empresa && system.empresa.token) {
            localStorage.token = system.empresa.token
        }
    }, [system.empresa])

    async function sendCheck() {
        setLoadingg(true)
        const { status, message } = await checkInfoEticket(dispatch, { telefone, pass_eticket })

        if(status) {
            window.location.href = `${window.location.origin}/${message}`
        } else {
            dispatch({
                type: 'SET_ALERT', 
                action: {open: true, message, type: 'error'}
            })
        }

        setLoadingg(false)
    }
    
    return (
        <Card open={true}>
            <SnackBar openModal={alert.open} message={alert.message} />
                <CardHeader title='Informe os dados do E-Ticket' />
                <CardContent>
                    <form className={classes.root} noValidate autoComplete="off">
                        <div>
                            <TextField
                                InputProps={{
                                    inputComponent: TelefoneMaskInput,
                                    value: telefone,
                                    onChange: e => setTelefone(e.target.value)
                                }}
                            />
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <TextField
                                label="Senha..."
                                value={ pass_eticket }
                                onChange={ e => setPass_eticket(e.target.value) }
                            />
                        </div>
                    </form>
                </CardContent>
                <CardActions>
                    <Grid container justify='space-between'>
                        <Button 
                            onClick={ () => props.history.push('/home') }
                            style={{ width: 100 }}
                            variant="outlined"
                        >
                            Cancelar
                        </Button>
                        <Button 
                            onClick={ sendCheck }
                            style={{ width: 100 }}
                            variant="outlined"
                            disabled={ loadingg || !telefone || !pass_eticket }
                        >
                            { loadingg ? <CircularProgress size={24} /> : 'Enviar' }
                        </Button>
                    </Grid>
                </CardActions>
            </Card>
    )
}

export default withRouter(Convenio)