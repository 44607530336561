import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Button, CircularProgress, Divider, Grid, TextField, Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import CustomGoogleMaps from '../maps/CustomGoogleMaps'
import Nameuser from './NameUser'
import AutocompletePlaces from '../maps/AutocompletePlaces'

import { getPlaceByGeocode, extractComponentPlace } from '../../helpers/getLocationMaps'

import { newAndSolicit } from '../../store/actions/corrida'

import { validateByComponentPlace } from '../../helpers/solicit'

import SnackBar from '../SnackBar'

const useStyles = makeStyles(theme => ({
   btn: {
      width: '95%',
      backgroundColor: '#eee',
      marginTop: 10
   },
   subHeader: {
      margin: 5
   },
   progress: {
      margin: theme.spacing(2),
   },
}))

function NewUser(props) {
   const dialogSearchBox                     = useSelector(state => state.configComponents.dialogSearchBox)
   const position                            = useSelector(state => state.position)
   const dispatch                            = useDispatch()
   const classes                             = useStyles()
   const [endereco, setEndreco]              = useState('')
   const [google_adress, setGoogle_adress]   = useState({})
   const [nameUser, setNameUser]             = useState(false)
   const [sortPlace, setSortPlace]           = useState('')
   const [alert, setAlert]                   = useState({ open: false, message: '' })

   const user     =  useSelector(state => state.user)
   const corrida  = useSelector( state => state.corrida )

   const getLocateInput = result => {
      const { formatted_address, address_components, geometry  }  = result
      const ext                                                   = extractComponentPlace(result.address_components)

      setEndreco(result.formatted_address)
      setGoogle_adress({formatted_address, address_components, geometry, extractdComponent: ext })
      dispatch({ type: 'LOAD_POSITION_SUCCESS', position: {lat: geometry.location.lat, lng: geometry.location.lng} })
   }

   const solicitByGoogle = async (pagamento) => {
      const { extractdComponent, geometry } = google_adress

      await newAndSolicit(dispatch, {
         endereco: { ...extractdComponent, latitude: geometry.location.lat, longitude: geometry.location.lng },
         nome: localStorage.nome,
         pagamento
      })

      return props.history.push('/corrida')
   }

   const clickSolicit = () => {
      const { errors } = validateByComponentPlace(google_adress.extractdComponent)
      if(errors.length) {
         dispatch({type: 'SET_ALERT', action: {open: true, message: errors[0], type: 'error'}})
      } else {
         setNameUser(true)
      }
   }

   useEffect(() => {
      const { extractdComponent } = google_adress

      if(extractdComponent) {
         const { logradouro, numero } = extractdComponent
         setSortPlace(
            logradouro && numero ?
               `${logradouro}, ${numero}`
            :
               (`${Object.keys(extractdComponent).map(key=>extractdComponent[key]).filter(_=>_)}`.replace(/,/gi, ', '))
         )
      }
   }, [google_adress.extractdComponent])

   useEffect(() => {
      if('status' in corrida.data && corrida.data.status) {
         return props.history.push('/corrida')
      }
   }, [corrida])

   useEffect(() => {
      if(position.status === 'localizado') {
         const getPlace = async () => {
            const { status, result } = await getPlaceByGeocode(position.coords)

            if(status && result.length) {
               const { formatted_address, address_components, geometry  }  = result[0]
               const ext                                                   = extractComponentPlace(address_components)
               
               setEndreco(formatted_address)
               setGoogle_adress({ formatted_address, address_components, geometry, extractdComponent: ext })
            } else {
               setEndreco('Não foi possível encontrar sua localização')
            }
         }

         getPlace()
      }
   }, [position.status])

   return (
      <div style={{ width: 'auto', height: '100vh' }}>

         <SnackBar openModal={alert.open} message={alert.message} />

         {  nameUser &&
            <Nameuser open={nameUser} close={() => setNameUser(false)} solicitTaxi={solicitByGoogle} />
         }

         {  dialogSearchBox &&
            <AutocompletePlaces currentPlace={endereco} getLocateInput={getLocateInput} />
         }

         <CustomGoogleMaps center={position.coords} zoom={14} />

         {  user.isFetching ?
               <Grid container direction="row" justify='center'>
                  <CircularProgress className={classes.progress} />
               </Grid>
            :
               <>
                  <Typography align='center' className={classes.subHeader}>Olá</Typography>

                  <Divider />

                  {  endereco &&
                     <Grid container direction="row" justify='center'>
                        <Typography variant='subtitle1' align='center'>
                           Onde estou:               
                        </Typography>
                        <TextField
                           style={{ width: '95%' }}
                           autoFocus={true} multiline={true} value={sortPlace}
                           onClick={() => dispatch({ type: 'OPEN_DIAOLOG_SEARCH' })}
                        />
                     </Grid>
                  }
                  
                  <Grid container direction="row" justify='center'>
                     <Button 
                        className={classes.btn} 
                        onClick={clickSolicit}
                     >
                        Pedir táxi
                     </Button>
                  </Grid>
               </>
         }
         

      </div>
   )
}

export default withRouter(NewUser)