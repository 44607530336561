import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Dialog, DialogContent, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
   progress: {
     margin: theme.spacing(2),
   },
}))

function LoadingRequest() {
   const dispatch = useDispatch()
   const circularProgress = useSelector( state => state.configComponents.circularProgress )
   const classes = useStyles()


   useEffect(() => {
      if (!circularProgress.status_spinner && circularProgress.message) {
         setTimeout(() => dispatch({ type: 'REQUEST_CLOSE' }), 4000)
      }
   }, [circularProgress.status_spinner])

   return (
      <Dialog open={circularProgress.open}>
         <DialogContent>
            <Grid container direction='row' justify='center'>
               <CircularProgress className={classes.progress} />
               <Typography variant='subtitle1' align='center'>{circularProgress.message}</Typography>
            </Grid>
         </DialogContent>
      </Dialog>
   )
}

export default LoadingRequest