import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getCurrentLocation }       from '../helpers/geolocation'

import NewUser                      from '../components/user/NewUser'
import UserRegistred                from '../components/user/UserRegistred'

function List(props) {
   const user              = useSelector( state => state.user )
   const position          = useSelector(state => state.position)
   const dispatch          = useDispatch()
   const ref               = useRef(position.status)
   ref.current             = position.status

   function getLocation() {
      getCurrentLocation(navigator, (err, data) => {
         if (err) {
            return dispatch({ type: 'LOAD_POSITION_ERROR', message: err })
         }

         return dispatch({ type: 'LOAD_POSITION_SUCCESS', position: data })
      })
   }

   const getLocationTimout = () => {//https://upmostly.com/tutorials/settimeout-in-react-components-using-hooks
      setTimeout(() => {
         if(ref.current === 'initial' || ref.current === 'error') {
            getLocation()
         }
      }, 5000)

      setTimeout(() => {
         if(ref.current === 'initial' || ref.current === 'error') {
            dispatch({
               type: 'SET_ALERT', 
               action: {open: true, message: 'Verifique se sua localização está ativada', type: 'error'}
            })
         }
      }, 10000)
   }

   useEffect(() => {   

      getLocation()
      getLocationTimout()      
      
   }, [])

   

   return (
      <div>
         {  user.status === 'ATIVO' && user.nome ?
               <UserRegistred />
            :  
               <NewUser />
         }
      </div>
   )
}

export default List