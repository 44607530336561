import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { 
   Button, Card, CardActions, CardHeader, CardContent, 
   Dialog, DialogTitle, DialogContent, DialogActions, 
   InputLabel, TextField, Grid
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
//import { makeStyles } from '@material-ui/core/styles'

import { make_code, validation_code } from '../services/api'

import { loadingDataEMpresa } from '../store/actions/system'

function TelefoneMaskInput(props) {
   const { inputRef, onChange, ...other } = props;

   return (
      <NumberFormat
         {...other}
         getInputRef={inputRef}
         onValueChange={values => {
            onChange({
               target: {
                  value: values.value,
               },
            });
         }}
         thousandSeparator
         isNumericString
         format='(##)#####-####'
         placeholder='( ## ) ##### - ####'
         mask={['#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#']}
      />
   );
}

function CodeMaskInput(props) {
   const { inputRef, onChange, ...other } = props;

   return (
      <NumberFormat
         {...other}
         getInputRef={inputRef}
         onValueChange={values => {
            onChange({
               target: {
                  value: values.value,
               },
            });
         }}
         thousandSeparator
         isNumericString
         format='#####'
         placeholder='__  __  __  __  __'
         mask={[' __ ', ' ___ ', ' __ ', ' __ ', ' __ ']}
      />
   );
}

TelefoneMaskInput.propTypes = {
   inputRef: PropTypes.func.isRequired,
   onChange: PropTypes.func.isRequired,
}

CodeMaskInput.propTypes = {
   inputRef: PropTypes.func.isRequired,
   onChange: PropTypes.func.isRequired,
}



function Home(props) {
   const dispatch                = useDispatch()
   const system                  = useSelector(state => state.system)
   const [telefone, setTelefone] = useState('')
   const [code, setCode]         = useState('')
   const [awaiting, setAwaiting] = useState(false)
   const [
      selectedParticular, 
      setSelectedParticular
   ] = useState(false)

   const empresa = system.empresa

   useEffect(() => {
      if (localStorage.hash && (system.pedido.status === '' || system.pedido.status === 'criado')) {
         return props.history.push(`/${localStorage.hash}`)
      } else {
         loadingDataEMpresa(dispatch)
      }
   }, [])

   const handleInputTelefone = e => {
      setTelefone(e.target.value)
   }

   const handleInputCode = e => {
      setCode(e.target.value)
   }

   const gerar = async () => {
      const { data } = await make_code({ telefone: telefone.replace(/\D/g, "") })

      if (data.status) {
         setAwaiting(true)
      } else {
         alert('Erro ao gerar pedido')
      }
   }

   const validar = async () => {
      const { data } = await validation_code({ telefone: telefone.replace(/\D/g, ""), code })

      if (data.status) {
         console.log(data)
         if(data.isHotel) {
            localStorage.hash = data.message
            window.location.href = `${window.location.origin}/hotel`
         } else {
            window.location.href = `${window.location.origin}/${data.message}`
         }
         
      } else {
         alert('Código informado inválido')
      }
   }

   return (
      <>
      { (empresa && empresa.e_ticket_enabled) && !selectedParticular
         ?
            <Card open={true}>
               <CardHeader title='Selecione o tipo da corrida' />
               <CardContent>
                  <Grid container justify='space-between'>
                     <Button
                        onClick={ () => setSelectedParticular(true) }
                        style={{ width: 100 }} 
                        variant="outlined"
                     >
                        Particular
                     </Button>

                     <Button 
                        style={{ width: 100 }} 
                        variant="outlined"
                        onClick={ () => props.history.push('/corrida_convenio') }
                     >
                        Empresa
                     </Button>
                  </Grid>
               </CardContent>
            </Card>
         :
         <>
            <Dialog open={awaiting}>
               <DialogTitle>Você receberá um código por SMS</DialogTitle>
               <DialogContent>
                  <TextField
                     autoFocus={awaiting}
                     InputProps={{
                        inputComponent: CodeMaskInput,
                        value: code,
                        onChange: handleInputCode
                     }}
                  />
               </DialogContent>
               <DialogActions>
                  <Button onClick={validar} variant="outlined">
                     validar
                  </Button>
               </DialogActions>
            </Dialog>
            <Card open={true}>
               <CardHeader title='Gerar novo pedido' />
               <CardContent>
                  <InputLabel htmlFor="input-telefone">Digite seu celular</InputLabel>
                  <TextField
                     id='input-telefone'
                     InputProps={{
                        inputComponent: TelefoneMaskInput,
                        value: telefone,
                        onChange: handleInputTelefone
                     }}
                  />
               </CardContent>
               <CardActions>
                  {  (empresa && empresa.e_ticket_enabled) 
                     ?
                        <Grid container justify='space-between'>
                           <Button 
                              onClick={ () => setSelectedParticular(false) }
                              style={{ width: 100 }}
                              variant="outlined"
                           >
                              Cancelar
                        </Button>
                           <Button onClick={gerar} variant="outlined">
                              Pedir
                           </Button>
                        </Grid>
                     :
                        <Button onClick={gerar} variant="outlined">
                           Pedir
                        </Button>
                  }
                  
               </CardActions>
            </Card>
         </>
      }
      </>
   )
}

export default withRouter(Home)

//https://github.com/s-yadav/react-number-format