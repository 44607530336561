const INITIAL_STATE = {
   data        : {},
   isCreating  : false,
   isFetching  : false,
   error       : false,
   messageError: ''
}

const corrida = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case 'CREATE_ADRESS_REQUEST':
         return { 
            ...state,
            isCreating: true
         }
      case 'CREATE_ADRESS_SUCCESS':
         return { 
            ...state,
            data: action.data,
            isCreating: false
         }
      case 'CREATE_ADRESS_ERROR':
         return { 
            ...state,
            error       : true,
            isCreating  : false,
            messageError: action.message
         }
      case 'SOLICIT_TAXI_REQUEST':
         return {
            ...state,
            isCreating: true,
         }
      case 'SOLICIT_TAXI_SUCCESS':
         return {
            ...state,
            isCreating: false
         }
      case 'SOLICIT_TAXI_ERROR':
         return {
            ...state,
            error       : true,
            messageError: action.message,
            isCreating  : false
         }
      case 'LOAD_RUN_REQUEST':
         return {
            ...state,
            isFetching: true,
         }
      case 'LOAD_RUN_SUCCESS':
         return {
            ...state,
            isFetching: false,
            data: action.data
         }        
      case 'LOAD_RUN_ERROR':
         return {
            ...state,
            isFetching: false,
            error: true,
            messageError: action.message
         }
      case 'LOAD_DATA_RUN':
         return {
            ...state,
            data: action.data
         }
      default:
         return state
   }
}

export default corrida