import axios from 'axios'

import { MAPS_API_KEY } from '../config.json'
///import { dispatch } from 'rxjs/internal/observable/range'

import { googlePlace } from '../services/api'
export const getPlaceByGeocode = async({ lat, lng }) => {
   try {
      const { data } = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MAPS_API_KEY}`)
      return { status: true, result: data.results }
   } catch(err) {
      console.log('Erro ao buscar localidade no Google Maps: ', err)
      return { status: false, message: err.message }
   }
}

export const getPlacesAutoComplete = async(objInput, sessiontoken, URL) => {
   try {
      let url_google = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${objInput.input}&language=pt-BR&key=${MAPS_API_KEY}&sessiontoken=${sessiontoken}`
      url_google = new URL(url_google)
      const { data } = await googlePlace({ url_google })
      if( data.status === "OK" ) return data.predictions
      else return []
   } catch (err) {
      console.log('Erro ao buscar dados no Google: ', err)
      return []
   }
}

export const getDetailPlaces = async(place_id, sessiontoken, URL) => {
   try {
      let url_google = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${place_id}&key=${MAPS_API_KEY}&language=pt-BR&sessiontoken=${sessiontoken}`
      url_google = new URL(url_google)
      const { data } = await googlePlace({ url_google })
      if( data.status === "OK" ) return data.result
      else return {}
   } catch (err) {
      console.log('Erro ao buscar dados no Google: ', err)
      return {}
   }
}

export const extractComponentPlace = (place) => {
   let end = {}
   end.logradouro = place.find( comp => comp.types.map( t => t === 'route' ).find(_=>_)  ) || {}
   end.numero     = place.find( comp => comp.types.map( t => t === 'street_number').find(_=>_) ) || {}
   end.bairro     = place.find( comp => comp.types.map( t => t === 'sublocality' ).find(_=>_) ) || {}
   end.cidade     = place.find( comp => comp.types.map( t => t === 'administrative_area_level_2' ).find(_=>_) ) || {}
   end.estado     = place.find( comp => comp.types.map( t => t === 'administrative_area_level_1' ).find(_=>_) ) || {}
   end.cep        = place.find( comp => comp.types.map( t => t === 'postal_code' ).find(_=>_) ) || {}
   Object.keys(end).forEach( key => {
      if(key === 'estado' && 'short_name' in end[key]) return end[key] = end[key].short_name
      if('long_name' in end[key]) return end[key] = end[key].long_name
      return end[key] = ''
   })

   return end
}

//https://gis.stackexchange.com/questions/279018/cors-error-when-using-google-elevation-api
//https://developers.google.com/places/web-service/details#PlaceDetailsRequests
//https://developers.google.com/places/web-service/autocomplete