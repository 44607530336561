import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

const RouteHotel = ({ component: Component, ...rest }) => {
    return (
        <Route { ...rest } render={props => {
           return <Component {...props} />
        } } />
    )
}

export default RouteHotel