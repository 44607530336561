const INNITIAL_STATE = {
   nome        : '',
   status      : 'innitial',
   enderecos   : [],
   isFetching  : false,
   isCreating  : false,
   error       : false,
   messageError: ''
}

const user = (state = INNITIAL_STATE, action) => {
   switch (action.type) {
      case 'LOAD_AUTENTICA_REQUEST': 
         return {
            ...state,
            isFetching  : true
         }
      case 'LOAD_AUTENTICA_SUCCESS': 
         return {
            ...state,
            nome        : action.data.nome,
            status      : action.data.status,
            enderecos   : action.data.enderecos,
            isFetching  : false
         }
      case 'LOAD_AUTENTICA_ERROR':
         return {
            ...state,
            error       : true,
            messageError: action.message,
            isFetching  : false
         }
      case 'CREATE_USER_REQUEST':
         return {
            ...state,
            isFetching  : true,
            isCreating  : true,
         }
      case 'CREATE_USER_SUCCESS':
         return {
            ...state,
            nome        : action.nome,
            status      : 'NAME_CREATED',
            isFetching  : false,
            isCreating  : false
         }
      case 'CREATE_USER_ERROR':
         return {
            ...state,
            error       : true,
            isCreating  : false,
            messageError: action.message
         }
      default:
         return state
   }
}

export default user