import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react'

import marker from '../../assets/images/blue_marker.png'

const AnyReactComponent = ({ text }) => <img src={marker} alt='marker' />

class SimpleMap extends Component {
   constructor(props) {
      super(props)
      this.state = {
         center: {
            lat   : -14.2350044,
            lng   : -51.9252815
         },
         zoom: 12,
         default: {
            center: {
               lat   : -14.2350044,
               lng   : -51.9252815
            },
            zoom: 12,
         }
      }
   }

   componentDidMount() {
      this.setState({ 
         center   : this.props.center,
         zoom     : this.props.zoom 
      })
   }

   componentDidUpdate(prevProps) {
      const { center: { lat, lng }, zoom } = prevProps
      //console.log(new Date())
      //lógica para postergar a redenrização das coordenadas do map,
      //pois ele nãoa estava atualizando quando as props eram atualizadas rapidamente
      if(lat !== this.props.center.lat && lng !== this.props.center.lng) {
         setTimeout(() => {            
            this.setState({
               ...this.state,
               center: {
                  lat: this.props.center.lat,
                  lng: this.props.center.lng
               },
               zoom
            })
         }, 500)         
      }
   }

   render() {
      
      return (
         // Important! Always set the container height explicitly
         <div style={{ height: '55vh', width: '100%' }}>
            <GoogleMapReact
               bootstrapURLKeys={{ key: 'AIzaSyCL1t_wnsNLWwQV42ABc4mYOo5Sp1t02Sw' }}
               defaultCenter={this.state.default.center}
               defaultZoom={this.state.default.zoom}
               center={this.state.center}
               zoom={this.state.zoom}
            >
               <AnyReactComponent
                  lat={this.props.center.lat}
                  lng={this.props.center.lng}
                  text="My Marker"
               />
            </GoogleMapReact>
         </div>
      );
   }
}

export default SimpleMap