import { combineReducers } from 'redux'

import configComponents from './configComponents'
import corrida          from './corrida'
import position         from './position'
import protaxi          from './protaxi'
import system           from './system'
import user             from './user'

export default combineReducers({
   configComponents,
   corrida,
   protaxi,
   position,
   system,
   user
})