import React, { useEffect, useState } from "react"
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { 
   AppBar, Dialog, DialogContent, 
   InputBase, IconButton, LinearProgress,
   List, ListItem, ListItemText, Paper
} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { ArrowBack, Clear } from '@material-ui/icons'
import uuidv4 from 'uuid/v4'

import { getPlacesAutoComplete, getDetailPlaces } from '../../helpers/getLocationMaps'

const useStyles = makeStyles(theme => ({
   paper: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',

   },
   appBar: {
      position: 'relative',
      backgroundColor: '#fff'
   },
   input: {
      marginLeft: theme.spacing(1),
      flex: 1
   },
   iconButton: {
      padding: 10
   }
}))

function AutocompletePlaces(props) {
   const dispatch = useDispatch()
   const classes = useStyles()
   const [search, setSearch] = useState('')
   const [session, setSession] = useState('')
   const [predictions, setPredictions] = useState([])
   const [loading, setLoading] = useState(false)

   useEffect(() => {
      setSession(uuidv4())
      setSearch(props.currentPlace)
   }, [])

   useEffect(() => {    

      if(search) {
         const getPredictions = async() => {
            setLoading(true)
            setPredictions(await getPlacesAutoComplete({ input: search }, session, window.URL))
            setLoading(false)
         }

         getPredictions()
      }

   }, [search])

   const choosePlaces = async (place_id, registred) => {
      if(!place_id) {
         props.getLocateInput(null, registred)
      } else {
         setLoading(true)
         const placeDetail = await getDetailPlaces(place_id, session, window.URL)
         setLoading(false)
         props.getLocateInput(placeDetail)
      }     

      dispatch({ type: 'CLOSE_DIAOLOG_SEARCH' })
   }

   const clear = () => {
      setSearch('')
      setPredictions([])
   }

   return (
      <Dialog open={true} fullScreen>
         <AppBar className={classes.appBar}>            
            <Paper className={classes.paper}>
               <IconButton
                  edge="start"
                  onClick={() => dispatch({ type: 'CLOSE_DIAOLOG_SEARCH' })}
                  aria-label="close"
               >
                  <ArrowBack />

               </IconButton>

               <InputBase
                  autoFocus
                  multiline={true}
                  className={classes.input}
                  placeholder='Digite um local...'
                  value={search}
                  onChange={e => setSearch(e.target.value)}
               />
               
               {  search &&
                  <IconButton
                     edge="start"
                     onClick={clear}
                     aria-label="clear"
                  >
                     <Clear />

                  </IconButton>
               }
            </Paper>
            { loading && <LinearProgress variant='query' /> }
         </AppBar>

         <DialogContent>
            <List>
               {  predictions.map( (p, i) =>
                     <div key={i}>
                        <ListItem disabled={loading} onClick={ () => choosePlaces(p.place_id) }>
                           <ListItemText primary={p.description} />
                        </ListItem>
                        <Divider />
                     </div>
                  )                  
               }
            </List>
            {  props.placesRegistreds && props.placesRegistreds.length 
               ?
                  <List>
                     {  props.placesRegistreds.map( (end,i) => {
                           return (
                              <>
                                 { end.logradouro && end.bairro && end.cidade &&
                                 
                                    <div key={'res-'+i}>
                                       <ListItem disabled={loading} onClick={ () => choosePlaces(null, end)}>
                                          <ListItemText 
                                             primary={`${end.logradouro}, ${end.bairro}, ${end.cidade}`}
                                             secondary='Endereço cadastrado'
                                          />
                                       </ListItem>
                                       <Divider />
                                    </div>
                                 }
                              </>
                           )
                        })
                     }                  
                  </List>
               : null
            }
         </DialogContent>
      </Dialog>
   )
}

export default AutocompletePlaces