import io from 'socket.io-client'

const env = process.env.NODE_ENV

export function registerToSocket() {
   /* const options = {
      query: {
         hash: localStorage.hash
      }
   }

   env === 'production' && (options.path = '/api/quero-taxi/socket.io')

   const socket = io(env === 'development' ? 'http://10.16.16.6:9004' : 'https://service.56k.com.br', options)
   */
   const socket = io('https://service.56k.com.br', {
      path: '/api/quero-taxi/socket.io',
      query: {
         hash: localStorage.hash
      }
   })

   socket.on('disconnect', () => {
      console.log('desconectado')
      socket.connect() 
   })


   socket.on('connect', data => {
      console.log('socket conectado')
      socket.on('test', s => console.log('a:' , s))
   })
   
   return socket
}
