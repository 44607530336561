import React from 'react'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'

function Confirm({openModal, closeModal, onConfirmed, label}) {
   
   return (
      <Dialog open={openModal} onClose={() => closeModal()}>
         <DialogContent>
            {label}
         </DialogContent>
         <DialogActions>
         <Button onClick={() => closeModal() }>Cancelar</Button>
         <Button onClick={onConfirmed}>OK</Button>   
         </DialogActions>
      </Dialog>
   )
}

export default Confirm