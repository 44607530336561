import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import { 
    Button, Card, CardHeader, 
    CardContent, CircularProgress, Dialog, DialogTitle,
    DialogContent, DialogActions, Grid, 
    TextField
} 
from '@material-ui/core'

import { createTicket } from '../services/api'
import { loading, loadingDataEMpresa } from '../store/actions/system'
import { solicitRun, newAndSolicit } from '../store/actions/corrida'
import NameUser from '../components/user/NameUser'

function TelefoneMaskInput(props) {
    const { inputRef, onChange, ...other } = props;
 
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                })
            }}
            thousandSeparator
            isNumericString
            format='(##)#####-####'
            placeholder='( ## ) ##### - ####'
            mask={['#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#']}
        />
    )
}

TelefoneMaskInput.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
}

function Hotel(props) {
    const dispatch  = useDispatch()
    const user      = useSelector(state => state.user)
    const system    = useSelector(state => state.system)
    /* const [dialog, setDialog] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [loadingTicket, setLoadingTicket] = useState(false) 
    const [location, setLocation] = useState(null) */
    const [dialogNameUser, setDialogNameUser] = useState(false)

    useEffect(() => {
        loadingDataEMpresa(dispatch)
        loading(dispatch, localStorage.hash)
    }, [])

    /* const handlePhoneNumber = ({ target }) => {
        setPhoneNumber(target.value)
    }
    
    const resetDialog = () => {
        setDialog(false)
        setPhoneNumber(null)
    } */

    /* const createTk = async () => {
        try {
            setLoadingTicket(true)

            const { data } = await createTicket({
                telefone: phoneNumber.replace(/\D/g, ""),
                noSMS: true
            })
            localStorage.telefone = phoneNumber.replace(/\D/g, "")
            localStorage.hash = data.hash
            localStorage.hotel_endereco_id = data.hotel_endereco_id

            setLoadingTicket(false)

            if(location === 1) {
                setNameUser(true)
            }

            if(location === 2) {
                return props.history.push('/user')
            }
        } catch (error) {
            console.log(error)
        }
    } */

    const solicit = async (pagamento) => {
        const empresa = system.empresa
        await newAndSolicit(dispatch, {
            endereco: { ...empresa.hotel_endereco },
            nome: user.nome,
            pagamento
        })
        return props.history.push('/corrida')
    }

    return (
        <>
            {/* <Dialog open={dialog}>
                <DialogTitle>Informe seu número de celular</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus={dialog}
                        InputProps={{
                            inputComponent: TelefoneMaskInput,
                            value: phoneNumber,
                            onChange: handlePhoneNumber
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Grid container justify='space-between'>
                        <Button onClick={ resetDialog } variant="outlined">
                            Cancelar
                        </Button>
                        <Button 
                            onClick={ createTk } 
                            variant="outlined"
                            disabled={
                                !phoneNumber ||
                                (phoneNumber && phoneNumber.length !== 11)
                            }
                        >
                            { loadingTicket 
                                ?
                                <CircularProgress style={{ width: '20px', height: '20px' }} />
                                :
                                'Enviar'
                            }
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog> */}

            
            <NameUser open={dialogNameUser} close={() => setDialogNameUser(false)} solicitTaxi={solicit} />
            

            <Card open={true}>
                <CardHeader title='Escolha o local para pedir um táxi' />
                <CardContent>
                    <Grid container justify='space-between'>
                        <Button 
                            variant="outlined"
                            onClick={ () => setDialogNameUser(true) }
                        >
                            Estou no Hotel
                        </Button>
                        <Button 
                            variant="outlined"
                            onClick={ () => props.history.push('/user') }
                        >
                            Minha Localização
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

export default withRouter(Hotel)
