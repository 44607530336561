import axios from 'axios'

import { API } from '../config.json'

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? window.location.origin : API
})

api.interceptors.request.use( config => {
  config.headers.common['Authorization'] = localStorage.token
  return config
}, error => {
  return Promise.reject(error)
})

export const autentica            = ()    => api.get(`/autentica/${localStorage.telefone}`)
export const solicita             = obj   => api.post('/solicita', obj)
export const newAdressAndSolicit  = obj   => api.post('/save_endereco_and_solicita', obj)
export const verifyHash           = hash  => api.get(`/verify_hash/${hash}`)
export const consultaRun          = ()    => api.get(`/consulta/${localStorage.telefone}`) 
export const cancelRun            = hash  => api.put(`/cancelar/${hash}`)
export const pushUser             = obj   => api.post('/push/user', obj)
export const make_code            = obj   => api.post(`/make_code`, obj)
export const validation_code      = obj   => api.post(`/validation_code`, obj)
export const protaxiCall          = obj   => api.post(`/protaxi-call`, obj)
export const label                = ()    => api.get('/empresa/label')
export const googlePlace          = obj   => api.post('/google/place/autocomplete', obj)
export const createTicket         = obj   => api.post('/create_ticket', obj)
export const check_eticket        = obj   => api.post('/check_eticket', obj)
