import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { HeadsetMic, Phone, WhatsApp } from '@material-ui/icons'
import { Button, Card, CardContent, CardActions, CardHeader, Fab, Link, Menu, MenuItem, Typography } from '@material-ui/core'
import { Dot } from 'react-animated-dots'

import { loadRun, actionCancelRun } from '../store/actions/corrida'
import { call } from '../store/actions/protaxi'
import { loading } from '../store/actions/system'

import Confirm from '../components/Corfirm'

const useStyles = makeStyles(theme => ({
   root: {
      backgroundColor: '#fff'
   },
   btnMap: {
      margin: theme.spacing(1),
      textAlign: 'center'
   },
   fab: {
      width: 52,
      height: 52,
      margin: theme.spacing(1),
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
   },
   text2:{
      color: '#5b5b5c'
   },
   iframe: {
      width: '100%',
      height: 'auto',
      marginTop: 10,
      marginBottom: 10
   }
}))

function Corrida(props) {
   const dispatch                      = useDispatch()
   const system                        = useSelector( state => state.system )
   const corrida                       = useSelector ( state => state.corrida )
   const [ menuFab, setMenuFab ]       = useState(null)
   const [openModal, setOpenModal]     = useState(false)
   const [btnLoading, setBtnLoading]   = useState(true)

   const classes  = useStyles()
   const cor      = (system.empresa) ? system.empresa.cor : '#f6f9fe'

   function handleClick(event) {
      setMenuFab(event.currentTarget)
   }

   function handleClose() {
      setTimeout(() => setMenuFab(null), 10)
   }

   function closeModal() {
      setOpenModal(false)
   }

   async function confirmed() {
      if( await actionCancelRun(dispatch, localStorage.hash ) )
         return props.history.push('/')

      closeModal()
   }

   async function falarMotorista() {
      call(dispatch, {
         origem: localStorage.telefone,
         destino: '16997659374'
      })
   }

   useEffect(() => {
      loadRun(dispatch)
      loading(dispatch, localStorage.hash)      
   }, [])

   useEffect(() => {
      if( 
         system.pedido.status === "cancelado"   || 
         system.pedido.status === "cancelada"   || 
         system.pedido.status === "expirado"    || 
         system.pedido.status === "finalizado"  || 
         system.pedido.status === "concluida" 
      ) {
         setBtnLoading(false)
         return props.history.push('/')
      }

      if(system.pedido.status === "despachada") {
         setBtnLoading(false)
      }
   }, [system.pedido])


   return (
      <React.Fragment>
         <Confirm openModal={openModal} closeModal={ closeModal } onConfirmed={ confirmed } label="Confirme o cancelamento da corrida" />

         <Card className={classes.root}>
            {  system.pedido.status === 'despachada'
               ? 
                  <CardHeader title="Minha Corrida" />
               :
                  null
            }

            {  ( btnLoading || system.pedido.status === 'aberta') && 

               <CardContent>
                  <Typography variant='h6'>Sua corrida está sendo processada, aguarde<Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></Typography>      
                  
                  <CardActions>
                     { (corrida.data && corrida.data.URL) &&
                        <Button 
                           className={classes.btnMap} 
                           href={ window.location.host === "cliente2.mpush.co" ?
                              'https://cliente2.mpush.co/static/preview_original.jpg'
                              :
                              corrida.data.URL
                           } 
                           target="_blank" variant="outlined"
                        >  
                           { window.location.host === "cliente2.mpush.co" ?
                              'Cancele sua Corrida'
                              :
                              'Acompanhe seu pedido'
                           }
                           
                        </Button>   
                     }
                                             
                  </CardActions>

               </CardContent>

            }

            { system.pedido.status === 'despachada' &&
               <>
                  <CardContent>
                     <Typography variant='subtitle2'>
                        Código da carrida: <span className={classes.text2}>{ corrida.data.num_corrida || '...' }</span>
                     </Typography>
                     <Typography variant='subtitle2'>
                        Nome do motorista: <span className={classes.text2}>{ corrida.data.nome_motorista || '...' }</span>
                     </Typography>
                     <Typography variant='subtitle2'>
                        Placa: <span className={classes.text2}>{ corrida.data.placa || '...' }</span>
                     </Typography>
                     <Typography variant='subtitle2'>
                        Modelo do Carro: <span className={classes.text2}>{ corrida.data.modelo || '...' }</span>
                     </Typography>
                     <Typography variant='subtitle2'>
                        Tempo Estimado: <span className={classes.text2}>{corrida.data.tempo_estimado || '...'}</span>
                     </Typography>

                     {  corrida.data.imagem &&
                        <Typography variant='subtitle2'>
                           <img width='100' src={corrida.data.imagem} alt='Carro' />
                        </Typography>
                     }  

                  </CardContent>

                  <CardActions>
                     <Button 
                        className={classes.btnMap} 
                        href={window.location.host === "cliente2.mpush.co" ?
                           'https://cliente2.mpush.co/static/preview_original.jpg'
                           :
                           corrida.data.URL
                        } 
                        target="_blank" 
                        variant="outlined"
                     >
                        Acompanhe seu carro
                     </Button>                           
                  </CardActions>
               </>                  
            }       
         </Card>
         
         {  system.empresa.whatsapp &&
            <Fab style={{backgroundColor: cor }} className={classes.fab} onClick={handleClick}>
               <HeadsetMic style={{color: '#fff'}} />
               <Menu
                  id='fab-menu'
                  keepMounted
                  anchorEl={menuFab}
                  open={Boolean(menuFab)}
                  onClose={handleClose}
               >
                  {/* <MenuItem onClick={falarMotorista}><Link color="inherit" underline="none"><Phone style={{marginRight:'5px', marginBottom: '-5px'}} />Falar com motorista</Link></MenuItem> */}
                  
                  <MenuItem>
                     <Link color="inherit" underline="none" href={`https://api.whatsapp.com/send?1=pt_BR&phone=${system.empresa.whatsapp}`}><WhatsApp style={{marginRight:'5px', marginBottom: '-5px'}} />
                        Fale com a central
                     </Link>
                  </MenuItem>
               
                  
               </Menu>
            </Fab>
         }
      </React.Fragment>
   )
}

export default Corrida
