import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

const isRunning = () => {
   const temp = localStorage.hashs ? JSON.parse(localStorage.hashs) : []

   if(temp.find( _ => _ === localStorage.hash)) {
      return true
   }

   return false
}

const RouteList = ({ component: Component, ...rest }) => {
   const system = useSelector( state => state.system )

   return (
      <Route { ...rest } render={props => {
         if( system.pedido.status === '' ) {
            if(localStorage.hash) {
               return <Redirect to={{ pathname: `/${localStorage.hash}`, state: {from: props.location} }} />
            }
            return <Redirect to={{ pathname: '/', state: {from: props.location} }} />
         }
         
         return <Component {...props} />
      } } />
   )
}

export default RouteList