const INITIAL_STATE = {
   isFetching  : false,
   error       : false,
   status      : false
}

const protaxi = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case 'CALL_PROTAXI_REQUEST':
         return {
            isFetching  : true
         }
      case 'CALL_PROTAXI_SUCCESS':
         return {
            isFetching  : false,
            status      : true
         }
      case 'CALL_PROTAXI_ERROR':
         return {
            isFetching  : false,
            error       : true
         }   
      default:
         return state
   }
}

export default protaxi