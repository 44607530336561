export function getCurrentLocation(navigator, callback) {

   function success({coords}) {
      return callback(null, { lat: coords.latitude, lng: coords.longitude })
   }

   function error() {
      return callback('Não foi possível pegar sua localização atual.')
   }

   if (!navigator.geolocation) {
      return callback('Seu navegador não suporta Geolocalização.')
   } else {
      navigator.geolocation.getCurrentPosition(success, error)
   }

}